import {
  action,
  computed,
  makeObservable,
  observable,
  onBecomeObserved,
} from "mobx";
import * as arrayUtils from "../utils/arrays";
import * as userService from "../requests/campaigns/users";

export class UsersStore {
  rootStore;

  @observable users;

  @observable isLoading = true;

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.users = [];

    onBecomeObserved(this, "users", this.lazyLoadUsers.bind(this));
  }

  lazyLoadUsers() {
    const { campaignId } = this.rootStore;
    this.retrieveUsersAsync(campaignId, { include_disabled: true });
  }

  @computed get activeUsers() {
    return this.users.filter((user) => !user.attributes.disabled);
  }

  @computed get salespersons() {
    const salespersons = this.users.filter(
      (user) => user.attributes.type === "Salesperson"
    );
    return salespersons;
  }

  @computed get managers() {
    const managers = this.users.filter(
      (user) => user.attributes.type === "Manager"
    );
    return managers;
  }

  @computed get nonPmUsers() {
    return this.users.filter((user) => !user.attributes.pmAccount);
  }

  @computed get workers() {
    const workers = this.users.filter(
      (user) => user.attributes.type === "Worker"
    );
    return workers;
  }

  @computed get activeManagers() {
    const managers = this.managers.filter((user) => !user.attributes.disabled)
    return managers;
  }

  getOwners(users) {
    return users.filter(
      (user) =>
        user.attributes.type === "Salesperson" ||
        user.attributes.type === "Manager"
    ).sort((a, b) => {
      if (!a.attributes.pmAccount && b.attributes.pmAccount) {
        return -1;
      }
      if (a.attributes.pmAccount && !b.attributes.pmAccount) {
        return 1;
      }
      return 0;
    });
  }

  @computed get owners() {
    return this.getOwners(this.users);
  }

  @computed get activeOwners() {
    return this.getOwners(this.activeUsers);
  }

  @action
  setIsLoading(loading) {
    this.isLoading = loading;
  }

  @action
  setUsers(users) {
    this.users = users;
  }

  findUser(userId) {
    return this.users.find(
      (user) => parseInt(user.id, 10) === parseInt(userId, 10)
    );
  }

  @action
  modifyUserInCache(userId, newUser) {
    const users = [...this.users];

    const userIndex = users.findIndex((user) => user.id === userId);

    if (userIndex !== -1) {
      users[userIndex] = newUser;

      this.setUsers(users);
    }
  }

  @action
  setCachedUsers(newUsers) {
    const allUsers = [...this.users, ...newUsers];

    const allUsersUnique = arrayUtils.uniqueOnId(allUsers, "id");

    this.setUsers(allUsersUnique);
  }

  @action
  async retrieveUsersAsync(campaignId, params = {}) {
    this.setIsLoading(true);

    try {
      const users = await userService.getCampaignUsers(
        campaignId,
        params
      );

      this.setUsers(users);
      this.setIsLoading(false);
      return users;
    } catch {
      this.setIsLoading(false);
      return;
    }
  }

  @action
  async updateUserAsync(campaignId, userId, payload) {
    this.setIsLoading(true);
    const user = await userService.updateCampaignUser(
      campaignId,
      userId,
      payload
    );

    this.modifyUserInCache(userId, user);

    this.setIsLoading(false);

    return user;
  }
}

export default UsersStore;
