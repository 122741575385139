import {
  action, makeAutoObservable, observable,
} from "mobx";
import * as userPerformanceService from "../requests/users/performances";
import * as campaignUserPerformancesService from "../requests/campaigns/users/performances";
import { IPerformance } from "../types/IPerformance";
import { RootStore } from "./RootStore";

export class PerformancesStore {
  rootStore;

  @observable performances: IPerformance[] = [];

  @observable isLoading = true;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  getCampaignUserPerformances(campaignId: number, userId: number) {
    return this.performances.filter(
      (performance) => performance.attributes.userId === userId
        && performance.attributes.campaignId === campaignId
        && performance.id === null,
    );
  }

  @action
  setPerformances(performances: IPerformance[]) {
    this.performances = performances;
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  async getPerformancesAsync(userId: string) {
    this.setIsLoading(true);
    const performances = await userPerformanceService.getPerformances(userId);

    this.setPerformances(performances);
    this.setIsLoading(false);

    return performances;
  }

  @action
  async getPerformancesForCampaignAndUserAsync(campaignId: number, userId: number) {
    this.setIsLoading(true);

    const cachedPerformances = this.getCampaignUserPerformances(campaignId, userId);
    if (cachedPerformances.length) {
      return cachedPerformances;
    }

    const performances = await campaignUserPerformancesService.getCampaignUserPerformancesAsync(
      campaignId.toString(),
      userId.toString(),
    );

    this.setPerformances([...this.performances, ...performances]);
    this.setIsLoading(false);

    return performances;
  }
}

export default PerformancesStore;
