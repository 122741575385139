import React from "react";
import classNames from "classnames";
import { TargetDarkIcon, TargetReachedIcon } from "wm-graphics";
import { Text } from "@wingmate/toolkit";
import { IPerformance } from "../../types/IPerformance";

import "./ScoreCardColumn.scss";

export interface IScoreCardColumnProps {
  performance: IPerformance;
  progressHeight: number;
  targetHeight: number;
}

export const ScoreCardColumn: React.FC<IScoreCardColumnProps> = ({
  performance,
  progressHeight,
  targetHeight,
}) => {
  const {
    complete, count, scTask, target,
  } = performance.attributes;

  const renderIcon = () => {
    if (!complete) {
      return <TargetDarkIcon data-testid="TargetDark" />;
    }
    return (
      <>
        <div className="Column__TargetLine" />
        <TargetReachedIcon data-testid="TargetReached" />
      </>
    );
  };

  const columnLabel = () => scTask.attributes.name;

  const columnClass = classNames("ScoreCardChart__Column", {
    "Column--complete": complete,
  });

  const isIconVisible = target !== 0;

  return (
    <div
      className={columnClass}
      data-testid="ScoreCardColumn"
      style={{ height: `${targetHeight}px` }}
    >
      <div className="ScoreCardChart__Target">
        {
            isIconVisible && (
              <div className="ScoreCardChart__Icon">{renderIcon()}</div>
            )
          }
        <div
          className="ScoreCardChart__Progress"
          style={{ height: `${progressHeight}px` }}
        />
      </div>
      <Text className="ScoreCardChart__ColumnCount" type="S">
        {`${count}/${target}`}
      </Text>
      <div className="ScoreCardChart__ColumnTitle">
        <div className="ScoreCardChart__Tick" />
        <div className="ScoreCardChart__TitleText">
          <Text type="S" weight="Light">
            {columnLabel()}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ScoreCardColumn;
