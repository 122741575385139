import * as axiosService from "../../axiosRequests";
import { endpoint } from "../../../utils/endpoint";
import { IPerformance } from "../../../types/IPerformance";

const campaignUserPerformancesEndpoint = (campaignId: string, userId: string) => endpoint(`app/v1.0/campaigns/${campaignId}/users/${userId}/performances`);

export const getCampaignUserPerformancesAsync = async (
  campaignId: string,
  userId: string,
): Promise<IPerformance[]> => {
  const response = await axiosService.axiosGet(
    campaignUserPerformancesEndpoint(campaignId, userId),
  );

  return response.data.data;
};

export default {
  getCampaignUserPerformancesAsync,
};
